import { createApp } from "vue";
import CoreLdeVue from "@lde/core_lde_vue";
import i18n from "@/modules/i18n";
import App from "./App.vue";
import getRouter from "./router";
import store from "./store";

const app = createApp(App);

// Configuration de l'application
app.use(i18n);
app.use(CoreLdeVue);
app.use(getRouter(i18n));
app.use(store);

app.mount("body");
