<template>
  <div id="app">
    <template v-if="user || $route.meta.public">
      <div id="wrapper">
        <header
          id="topbar"
          class="flex-vcenter"
        >
          <nav>
            <router-link :to="{ name: 'accueil', }">
              <img
                src="@lde/core_lde_vue/dist/assets/media/logos/logo_lde.svg"
                alt="Logo LDE"
              />
            </router-link>
          </nav>
          <div
            v-if="user"
            id="topbar_right_zone"
          >
            <ButtonPopover
              v-if="servicesInDropdown?.length"
              id="popover_assistance"
              v-model="isPopoverAssistanceOpen"
              :class="{ open: isPopoverAssistanceOpen, }"
              icon="both"
              placement="bottom-left"
              trigger="click"
              variant="solid"
            >
              <template #left-icon>
                <UilCommentDots />
              </template>
              <template #label>
                <strong class="text-medium">{{ $t("general.assistance") }}</strong>
              </template>
              <template #right-icon>
                <UilAngleDown />
              </template>
              <ul class="text-medium">
                <li
                  v-for="service in servicesInDropdown"
                  :key="service.code"
                >
                  <component
                    :is="buttonType(service)"
                    v-bind="buttonAttr(service)"
                    @click="(service.action && service.action()) || undefined"
                  >
                    <UilCommentQuestion v-if="service.code === 'demande'" />
                    <img
                      v-else
                      :src="service.image"
                      :alt="`Logo du service ${service.nom}`"
                    />
                    <span>{{ service.nom }}</span>
                  </component>
                </li>
              </ul>
            </ButtonPopover>
            <AvatarButton
              v-model="isPopoverAvatarOpen"
              :user="user"
              :notifications="notifications"
              :infos-user="infosUser"
              @clear-notifications="clearNotifications()"
              @disconnect="disconnect()"
            />
          </div>
        </header>

        <main>
          <router-view />
        </main>
      </div>

      <MainFooter>
        <template #left-links>
          <router-link :to="{ name: 'default_mentions_legales', }">
            {{ $t('legal.mentions-legales') }}
          </router-link>
          <router-link :to="{ name: 'default_cgu', }">
            {{ $t('legal.cgu') }}
          </router-link>
          <router-link :to="{ name: 'rgpd', }">
            {{ $t('legal.rgpd') }}
          </router-link>
          <!-- TODO: À mettre si on a des cookies autres qu'acceptés par défaut. -->
          <!-- <button
            class="button"
            @click="showCookies = true"
            >
            Cookies
            </button> -->
        </template>
      </MainFooter>
    </template>

    <div
      v-else
      id="app_loader"
      class="flex-vcenter"
    >
      <InfiniteLoader
        :height="64"
        :width="64"
      />
    </div>
  </div>
</template>

<script>
import {
  ButtonPopover,
  AvatarButton,
  MainFooter,
  InfiniteLoader,
  copyToClipboard,
} from "@lde/core_lde_vue";

import { UilCommentDots, UilAngleDown, UilCommentQuestion } from "@lde/vue-unicons";

import Api from "@/modules/axios";

import config from "@/config";

import { mapGetters } from "vuex";

/**
 * Structure de base du projet.
 */
export default {
  name: "App",
  components: {
    ButtonPopover,
    AvatarButton,
    MainFooter,
    InfiniteLoader,
    UilCommentDots,
    UilAngleDown,
    UilCommentQuestion,
  },
  data() {
    return {
      // TODO: voir plus tard les notifications de ce projet
      notifications: {
        count: 0,
        results: [],
      },
      isPopoverAssistanceOpen: false,
      isPopoverAvatarOpen: false,
    };
  },

  computed: {
    ...mapGetters(["services", "user", "country"]),
    isDe() {
      return this.country === "de";
    },
    servicesInDropdown() {
      let actionDemande = { code: "demande" };
      if (this.isDe) {
        actionDemande = {
          ...actionDemande,
          nom: this.mailLdeDe,
          action: () => this.copyToClipboard(this.mailLdeDe),
        };
      } else if (this.services.some((service) => service.groupe === "hotline")) {
        actionDemande = {
          ...actionDemande,
          nom: this.$t("general.faire-une-demande"),
          url: process.env.VUE_APP_HOTLINE_URL,
        };
      }

      return [
        actionDemande,
        ...this.services.filter((service) => config.servicesInDropdown.includes(service.groupe)),
      ];
    },
    mailLdeDe() {
      return config.mailsDe.lde;
    },
    infosUser() {
      return [
        { label: "Identifiant", value: this.user.username },
      ];
    },
  },
  methods: {
    copyToClipboard,
    /**
     * Définit la balise HTML d'un service du dropdown.
     * @param {Object} service Données concernant le service.
     * @returns {String} Balise HTML souhaitée.
     */
    buttonType(service) {
      if (service.code.includes("statuts_services")) {
        return "router-link";
      }
      if (service.action) {
        return "button";
      }
      return "a";
    },
    /**
     * Définit les attributs HTML d'un service du dropdown.
     * @param {Object} service Données concernant le service.
     * @returns {Object} Attributs HTML souhaités.
     */
    buttonAttr(service) {
      if (service.code.includes("statuts_services")) {
        return { to: { name: "statuts_services" } };
      }
      if (service.action) {
        return { class: "button" };
      }
      return {
        href: service.url,
        rel: "noreferrer noopener",
      };
    },
    /**
     * Déconnecte l'utilisateur.
     * @public
     */
    disconnect() {
      window.location = config.logoutUrl;
    },
    /**
    * Supprime toutes les notifications.
    * @public
    */
    clearNotifications() {
      this.notifications.results.forEach((element) => {
        Api()
          .patch(`notification/${element.id}/`, { date_lecture: new Date().toISOString() })
          .then(() => {
            const index = this.notifications.results.findIndex((id) => id === element.id);
            this.notifications.results.splice(index, 1);
            this.notifications.count -= 1;
          }).catch((e) => {
            console.error(e);
          });
      });
    },
  },
};
</script>
<style lang="scss">
@use "@/assets/styles/app.scss";
</style>
