import { createI18n } from "vue-i18n";
import { getBrowserLocale, supportedLocalesInclude } from "@/modules/utils";
import { fr as cfr, de as cde } from "@lde/core_lde_vue/dist/modules/i18n";
import { getCountry } from "@/config";

const fr = require("@/locales/fr.json");
const de = require("@/locales/de.json");

// Pour fusionner deux objets en profondeur.
const merge = (target, source) => {
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  Object.keys(source).forEach((key) => {
    if (source[key] instanceof Object) {
      Object.assign(source[key], merge(target[key], source[key]));
    }
  });

  // Join `target` and modified `source`
  Object.assign(target || {}, source);
  return target;
};

let locale = getBrowserLocale({ countryCodeOnly: true });
if (!supportedLocalesInclude(locale)) {
  locale = getCountry();
}

const config = {
  legacy: false, // Vous devez définir `legacy: false` pour Vue 3
  globalInjection: true, // Permet d'utiliser $t globalement
  locale,
  fallbackLocale: "fr",
  messages: {
    fr: merge(fr, cfr),
    de: merge(de, cde),
  },
};
export default createI18n(config);
